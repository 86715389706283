import React, { Component } from 'react';
import moment from 'moment';
import axiosApi from '../../../axiosApi'

//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
//import moment from 'moment';
//import { Pie } from 'react-chartjs-2';
//import { Link }  from 'react-router-dom';

//Paginas
import Menu from '../../../template/menuBlog/index';
import Footer from '../../../template/footerBlog/index';
import './index.css';
import { defaultFormat } from 'moment';

/* img */

//function nl2br(texto) {
//  return texto.replace(new RegExp('\r?\n','g'), '<br />');
//}

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          artigos: [],
          maisLidos: [],
          pesquisa: props.match.params.slug,
        }
        localStorage.setItem('idArtigo', '');
        this.getPesquisa(props.match.params.slug);
        

        //pageView.log('home');
      }
      
      getPesquisa = async (url) =>{
        await axiosApi.get("/artigo/search/"+ url,
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {        
            //Dados Pessoais
            //console.log(res.data.artigo[0]._id);
            //localStorage.setItem('idArtigo', res.data.artigo[0]._id);
            //this.getArtigo(res.data.artigo[0]._id);
             //Dados Pessoais
             if(res.data.artigo.length == 0){
              this.setState({
                "artigos": 0,
              });
             }else{
                this.setState({
                  "artigos": res.data.artigo,
                });
                this.getArtigosMaisLidos();
                console.log("Pesquisa");
                console.log(res.data.artigo);
             }       
             this.getArtigosMaisLidos();   
        })
        .catch(error => {
          console.log(error);
        })    
        
      
      }

      getArtigos = async () => {
        const _token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMTY0OWQ4MGE4ZTNjM2RlZjNiNGFlZSIsImlhdCI6MTY0NjIyNjUxNCwiZXhwIjoxNjQ2MzEyOTE0fQ.4vIpciJqHduBPf-XzDuqm1YuFIrBhfo177rSLhWVvIU";
        const baseUrl = "http://localhost:3001";
        //document.querySelector('.button-red').style.display = "none";
        //document.querySelector('.button-green').style.display = "none"; 
        await axiosApi.get("/artigo", 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Referer': "https://blog.breese.com.br/",
          },
        })        
        .then(res => {        
            //Dados Pessoais
            this.setState({
             "artigos": res.data.artigo,
            });
            this.getArtigosMaisLidos();
            console.log("Listagem");
            console.log(res.data.artigo);

        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      getArtigosMaisLidos = async () => {
        const _token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMTY0OWQ4MGE4ZTNjM2RlZjNiNGFlZSIsImlhdCI6MTY0NjIyNjUxNCwiZXhwIjoxNjQ2MzEyOTE0fQ.4vIpciJqHduBPf-XzDuqm1YuFIrBhfo177rSLhWVvIU";
        const baseUrl = "http://localhost:3001";
        //document.querySelector('.button-red').style.display = "none";
        //document.querySelector('.button-green').style.display = "none"; 
        await axiosApi.get("/artigo/maislidos", 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Referer': "https://blog.breese.com.br/",
          },
        })        
        .then(res => {        
            //Dados Pessoais
            this.setState({
             "maisLidos": res.data.artigo,
            });
            console.log("Mais Lidos");
            console.log(res.data.artigo);
        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.sidenav');
          var instances = M.Sidenav.init(elems, {});
        });

        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.tooltipped');
          var instances = M.Tooltip.init(elems, {});
        });
        
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }

      retornaPorcentagem(like, dislike){
        let porcentagem = 0;
        porcentagem = (like / (like + dislike)) * 100;
        if(!porcentagem){
          porcentagem = 0;
        }
        return Math.round(porcentagem);
      }

      retornaViews(views){
        let view = 0;
        if(views >= 1000){
          view = Math.round(views/1000) + "k";
        }
        if(views >= 1000000){
          view = Math.round(views/1000000) + "M";
        }
        if(views >= 1000000000){
          view = Math.round(views/1000000) + "B";
        }
        if(views < 1000){
          view = views;
        }
        return view;
      }

      retornaData(data){
        let mes = ["meses", "Jan", "Fev", "Mar", "Abr", "Mai", " Jun", "Jul", "Ago", "Set", "Out",
      "Nov", "Dez"]
        console.log(data);
        data = moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
        let dia = data.substr(0,2);
        let numMes = parseInt(data.substr(3,5));
        let ano = data.substr(6,9);
        let date = "";
        let now = new Date();
        now = moment(now, "YYYY-MM-DD").format("DD/MM/YYYY")
        let diff = moment(data,"DD/MM/YYYY").diff(moment(now,"DD/MM/YYYY"));
        let dias = moment.duration(diff).asDays();
        console.log(dias);
        if(data == now){
          date = "Hoje";
        }else if(dias == -1){
          date = "Ontem";
        }else{
          date = dia + " " + mes[numMes] + " " + ano;
        }
        return date;
      }

      retornaTextoCortado(texto, tipo){
        // 0 = Titulo esquerda
        // 1 = Descrição Esquerda
        // 2 = Titulo direita
        let textoAlterado = "";
        if(tipo == 0 && texto){
          if(texto.length < 112){
            textoAlterado = texto.substr(0,112);
          }else{
            textoAlterado = texto.substr(0,108) + "...";
          }
          
        }else if(tipo == 1 && texto){
          if(texto.length < 162){
            textoAlterado = texto.substr(0,162);
          }else{
            textoAlterado = texto.substr(0,159) + "...";
          }
          
        }else if(tipo == 2 && texto){
          if(texto.length < 110){
            textoAlterado = texto.substr(0,110);
          }else{
            textoAlterado = texto.substr(0,107) + "...";
          }
          
        }
        return textoAlterado;
      }

      lerArtigo = e =>{
        e.preventDefault();
        //localStorage.setItem('idArtigo', e.target.id);
        window.location.assign("/artigo/" + e.target.id);
      }

      render(){
        const {artigos, maisLidos, pesquisa} = this.state;
        return(
          <div className="homeBlog"> 
          <Menu />
            <div className='artigos row'>
              <div className='artigoEsquerdo' >
                <ul className="collection"> 
                {artigos != 0 
                ? 
                  <h5>Pesquisa: {pesquisa}</h5>
                : 
                  ""  
                }
                {
                artigos != 0 ? artigos.map(artigo => (
                <li className="collection-item" key={artigo._id}>
                  <img classname="tooltipped responsive-img" align="left" src={artigo.imgGrande} alt={artigo.titulo} data-position="bottom" data-tooltip={artigo.titulo}/>
                  <a href='' onClick={this.lerArtigo} id={artigo.url}>
                    <span className="title flow-tex" id={artigo.url}>{this.retornaTextoCortado(artigo.titulo, 0)}</span>
                    <br/>
                    <span className='text flow-tex' id={artigo.url}>{this.retornaTextoCortado(artigo.descricao, 1)}</span>
                    <br/><br/>
                  </a>
                  <span className='base' id={artigo.url}>
                    <span className='date'>{this.retornaData(artigo.createdAt)}</span>
                    <span className='textIcon'>{this.retornaViews(artigo.views)}</span><i className="material-icons">visibility</i>
                    <span className='textIcon'>{this.retornaPorcentagem(artigo.like, artigo.dislike)}%</span><i className="material-icons">grade</i>
                  </span>
                </li>
                ))
              :
                  <h4>
                    Nenhum artigo encontrado sobre o tema <b><i>{pesquisa}</i></b> !
                  </h4>
              }
                </ul>
              </div>
              <div className='artigoDireito' >
                <p className='more'>Mais lidas</p>
                <ul className="collection">
                  {maisLidos.map(art =>(
                    <li className="collection-itens" key={art._id}>
                      <a href='' onClick={this.lerArtigo} id={art.url}>
                        <span className="title left-align" id={art.url}>{this.retornaTextoCortado(art.titulo,2)}</span>
                        <br/>
                        <span className="date" id={art.url}>{this.retornaData(art.createdAt)}</span> 
                        <br/>
                      </a>
                      <img className="responsive-img"  src={art.imgGrande} alt={art.titulo}/>
                    </li>
                  ))}
                </ul>
              </div>              
            </div>
            <Footer />
        </div>
      );
    }
}
export default Home;