import React, { Component } from 'react';
import axiosApi from '../../../axiosApi';
import moment from 'moment';

//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
//import moment from 'moment';
//import { Pie } from 'react-chartjs-2';
//import { Link }  from 'react-router-dom';

//Paginas
import Menu from '../../../template/menuBlog/index'
import './index.css';
import { defaultFormat } from 'moment';

/* img */

//function nl2br(texto) {
//  return texto.replace(new RegExp('\r?\n','g'), '<br />');
//}

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          artigos: [],
          maisLidos: [],
          texto: '',
          titulo: '',
          textoHTML: "",
        }
        localStorage.setItem('idArtigo', '');
        
        this.getArtigos();
        //this.senhaAdmin();
        

        //pageView.log('home');
      }
      /*
      getHttp = () =>{
        const {id} = this.state;
  
        apiUrl.get(`/usuario/${id}/`).then( res => {
            if( res.data.dadosBancarios && res.data.dadosBancarios.length > 0 ){
              this.setState({dadosBanco: true});
            }
            if( res.data.endereco ){
              this.setState({dadosEndereco: true});
            }

            //Dados Pessoais
            this.setState({
              "id": res.data.id,
              "nome": res.data.nome,
              "cpf": res.data.cpf,
              "email": res.data.dadosCadastro.email,
              "telefone": res.data.dadosCadastro.telefone || "",
              "dtCadastro": res.data.dadosCadastro.dataCadastro,
              "dtNascimento": res.data.dadosCadastro.dataNascimento || "",
              "sexo": res.data.dadosCadastro.sexo || "",
              "taxa": "7",
              "status": "2",
              "icon": ""
            });
  
          })
          .catch( error => {
              console.log(error);
              this.toastMensagem("Erro ao trazer dados do usuário, faça login novamente. Error: " + error);
          });
      }
      listarCursos = () =>{
        apiUrl.get("/cursos/resumo")
        .then(res => {
  
            let temas = {};
            for ( let c of res.data ) {
              if ( temas[ c.temas ] )
                temas[ c.temas ].push( c );
              else
                temas[ c.temas ] = [ c ]; 
            }
  
            this.setState({ 
              cursos: JSON.parse(JSON.stringify(res.data)),
              temas: temas,
            });
  
        })
        .catch(error => {
          console.log(error);
        })
      }
      listarMeusCursos = () =>{
        apiUrl.get("/usuario-curso/" + this.state.idUsuario)
        .then(res => {            
            this.setState({ 
              meusCursos: JSON.parse(JSON.stringify(res.data)),
              tenhoCurso: res.data.length,
            });
        })
        .catch(error => {
          console.log(error);
        })
      }
      listarMorningCalls = () => {
        this.setState({ 
          morningCall: [],
        });
        apiUrl.get("/morning-call/" + this.state.morningCallPage)
        .then(res => {
            this.setState({ 
              morningCall: [].concat( this.state.morningCall, res.data ),
            });
        })
        .catch(error => {
          console.log(error);
        })
      }
      listarLives = () => {
        apiUrl.get("/video-live/")
        .then(res => {
            this.setState({ 
              lives: res.data,
            });
        })
        .catch(error => {
          console.log(error);
        })
      }
      carregarMaisMorningCalls = () => {
        this.setState({ morningCallPage: this.state.morningCallPage + 1});
        this.listarMorningCalls();
      }
      listarAulasAoVivo = () => {
        var aulasVivo = new Array();
        var cont = -1;
        this.setState({ 
          aulasAoVivo: [],
        });
        
        apiUrl.get("/video-live/")
        .then(res => {
          for(var i = res.data.length; i>-1; i--){
            aulasVivo[cont] = res.data[i];
            cont ++;
          }

          this.setState({ 
            aulasAoVivo: [].concat( this.state.aulasAoVivo, aulasVivo),
          });
        })
        .catch(error => {
          console.log(error);
        })
      }

      /*
      verificaHoraAula = () => {
        var horaAtual = new Date();
        var atual = parseInt(moment(horaAtual).format('HH'));
        var programado = parseInt(moment(this.hora).format('HH'));
        if( atual >=  ( programado - 1)  ||  atual <=  (programado + 4)){
          return true;
        }else{
          return false;
        }
      }
      */
      /*
      listarCarteiras = async () => {
        try{
          await apiUrl.get('/carteiras/')
          .then(res => {
              this.setState({ 
                idCarteira: res.data[0].id,
                aportes: res.data[0].aportes,
                carteira: res.data[0],
              });
              this.obterSaldoCarteira( res.data[0].id );
          })
        }catch(error){
            this.setState({"mensagem":error});
        } 
      } 
      listarCarteiraQuattro = (cod) =>{
        var label = new Array();
        var data = new Array();
        
        apiUrl.get("/carteira-quattro/carteira")
        .then(res => { 
            this.setState({ listaCarteira: res.data });
        })
        .catch(error => {
          this.toastMensagem(error);
        })
        
  
        apiUrl.get("/carteira-quattro/carteira/resumido")
        .then(res => {
            this.setState({ listaCarteiraResumida: res.data });
            for(var i = 0; i < res.data.length; i++){
              label[i] = res.data[i].tipo;
              data[i] = (res.data[i].porcentagem).toFixed(2, ",");
            }
            this.dadoosGraficoCarteiraQuattroResumida(label,data);
        })
        .catch(error => {
          this.toastMensagem(error);
        })
      }
      obterSaldoCarteira = async (idCarteira) => {
        try{
          await apiUrl.get(`/carteira/${idCarteira}/saldo`)
          .then(res => {
              this.setState({ saldo: res.data / 100 });
          })
        }catch(error){
            this.setState({"mensagem":error});
        } 
      }
      
      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
      dadoosGraficoCarteiraQuattroResumida(labelDados, dados){
          const data = {
            labels: labelDados,
            datasets: [{
              data: dados,
              backgroundColor: [
              '#062f4f',
              '#8b2621',
              '#0b0025',
              '#778899',
              '#2E8B57',
              '#8B4513',
              ],
              hoverBackgroundColor: [
              '#062f4f',
              '#8b2621',
              '#0b0025',
              '#778899',
              '#2E8B57',
              '#8B4513',
              ]
            }]
          };
          this.setState({dataCarteiraQuattro: data})
      }
      mensagemAdquiriCursos = async e => {
        e.preventDefault();
        this.toastMensagem("Aquiria nosso Curso de Investimentos e Negócios");
      }
      retornaAsterisco = (tamanho) => {
        var texto = "";
        for(var i = 0; i < tamanho; i++){
          texto+= "* "
        }
        return texto;
      }*/
     
      changeHandler = e => {
        if(e.target.name === "texto"){
          this.setState({[e.target.name]: e.target.value});
          this.retornaTextoHtml(e.target.value);
        }else{
          this.setState({[e.target.name]: e.target.value});
        }
        
      }

      retornaTextoHtml = (t) => {
        var p = document.getElementById("exibirTexto");
        p.innerHTML = t;
      }
      
      
      getArtigos = async () => {
        await axiosApi.get("/artigo/todos", 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {        
            //Dados Pessoais
            this.setState({
             "artigos": res.data.artigo,
            });
            this.getArtigosMaisLidos();
            console.log("Listagem");
            console.log(res.data.artigo);

        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      senhaAdmin = () =>{
        var resultado = window.prompt("Digite a senha do Admin", "*******");
        if (resultado) {
          if(resultado === "21994558100"){
            this.toastMensagem("Conectado");
          }else{
            window.location.replace("/blog/");
          }
          
        }else{
          window.location.replace("/blog/");
        }
      }

      getArtigosMaisLidos = async () => {
        await axiosApi.get("/artigo/maislidos", 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {        
            //Dados Pessoais
            this.setState({
             "maisLidos": res.data.artigo,
            });
            console.log("Mais Lidos");
            console.log(res.data.artigo);
        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.sidenav');
          var instances = M.Sidenav.init(elems, {});
        });
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.tabs');
          var instance = M.Tabs.init(elems, {});
        });
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.collapsible');
          var instances = M.Collapsible.init(elems, {});
        });

      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }

      retornaPorcentagem(like, dislike){
        let porcentagem = 0;
        porcentagem = (like / (like + dislike)) * 100;
        if(!porcentagem){
          porcentagem = 0;
        }
        return Math.round(porcentagem);
      }

      retornaViews(views){
        let view = 0;
        if(views >= 1000){
          view = Math.round(views/1000) + "k";
        }
        if(views >= 1000000){
          view = Math.round(views/1000000) + "M";
        }
        if(views >= 1000000000){
          view = Math.round(views/1000000) + "B";
        }
        if(views < 1000){
          view = views;
        }
        return view;
      }

      retornaData(data){
        let mes = ["meses", "Jan", "Fev", "Mar", "Abr", "Mai", " Jun", "Jul", "Ago", "Set", "Out",
      "Nov", "Dez"]
        data = moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
        let dia = data.substr(0,2);
        let numMes = parseInt(data.substr(3,5));
        let ano = data.substr(6,9);
        let date = "";
        let now = new Date();
        now = moment(now, "YYYY-MM-DD").format("DD/MM/YYYY")
        let diff = moment(data,"DD/MM/YYYY").diff(moment(now,"DD/MM/YYYY"));
        let dias = moment.duration(diff).asDays();
        if(data == now){
          date = "Hoje";
        }else if(dias == -1){
          date = "Ontem";
        }else{
          date = dia + " " + mes[numMes] + " " + ano;
        }
        return date;
      }

      retornaTextoCortado(texto, tipo){
        // 0 = Titulo esquerda
        // 1 = Descrição Esquerda
        // 2 = Titulo direita
        let textoAlterado = "";
        if(tipo == 0 && texto){
          textoAlterado = texto.substr(0,92) + "...";
        }else if(tipo == 1 && texto){
          textoAlterado = texto.substr(0,120) + "...";;
        }else if(tipo == 2 && texto){
          textoAlterado = texto.substr(0,84) + "...";
        }
        return textoAlterado;
      }

      lerArtigo = e =>{
        e.preventDefault();
        this.toastMensagem(e.target.id);
        localStorage.setItem('idArtigo', e.target.id);
        window.location.replace("/blog/artigo/");
      }

      escreverTexto = async () => { 
        var text = document.getElementById("texto").value;
        var textoPronto = text.replace(/"/g, "\'").replace(/(\r\n|\n|\r)/gm, "");
        navigator.clipboard.writeText(textoPronto.trim());
        this.toastMensagem(textoPronto);
      }
      criarURL = async () => { 
        var text = document.getElementById("titulo").value;
        //var textoPronto = text.replace(/[^a-zA-Zs]/g, "").toLowerCase().replace(/(\r\n|\n|\r)/gm, "").split(" ").join("-");
        //var textoPronto = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().split(" ").join("-");
        var textoPronto = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove acentos
        .split(" ").join("-")
        .replace(':', '') // Substitui espaço e outros caracteres por hífen
        .replace('?', '') // Substitui espaço e outros caracteres por hífen
        .replace('!', '') // Substitui espaço e outros caracteres por hífen
        .replace('.', '') // Substitui espaço e outros caracteres por hífen
        .replace(',', '') // Substitui espaço e outros caracteres por hífen
        .replace(';', '') // Substitui espaço e outros caracteres por hífen
        .replace(/\-\-+/g, '-')	// Substitui multiplos hífens por um único hífen
        .replace(/(^-+|-+$)/, '')
        .replace(/\-\-+/g, '-')
        .toLowerCase(); 
        navigator.clipboard.writeText(textoPronto.trim());
        this.toastMensagem(textoPronto);
      }

      render(){
        const {artigos, maisLidos, texto, titulo, textoHTML} = this.state;
        return(
          <div className="adminBlog"> 
          <Menu />
            <div className="row">
              <div className="col s12">
                <ul className="tabs">
                  <li className="tab col s3"><a href="#test1" className="active">Artigos</a></li>
                  <li className="tab col s3"><a href="#test2">Autor / Temas</a></li>
                  <li className="tab col s3"><a href="#test3">Ainda não sei</a></li>
                  <li className="tab col s3"><a href="#test4">Ainda não sei</a></li>
                </ul>
              </div>
              <div id="test1" className="col s12">
                Artigos
                <ul className="collapsible">
                  <li>
                    <div className="collapsible-header"><i className="material-icons">filter_drama</i>Escrever</div>
                    <div className="collapsible-body">
                      <div className="row">
                        <form className="col s12">
                          <div className="row">
                            <div className="input-field col s8">
                              <textarea id="titulo" name="titulo" value={titulo} onChange={this.changeHandler} className="materialize-textarea" required></textarea>
                              <label htmlFor="titulo">Titulo</label>
                            </div>
                            <div className="input-field col s4">
                              <a className='btn' onClick={this.criarURL}>Criar URL</a>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s12">
                              <textarea id="texto" name="texto" value={texto} onChange={this.changeHandler} className="materialize-textarea" required></textarea>
                              <label htmlFor="texto">Texto</label>
                            </div>
                          </div>
                        </form>
                      </div>
                      <a className='btn' onClick={this.escreverTexto}>Escrever</a>
                      <div>
                        <p id='exibirTexto' className='exibeTxt'>
                          
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="collapsible-header"><i className="material-icons">place</i>Artigos</div>
                    <div className="collapsible-body">
                    <ul className="collapsible">
                      {artigos.map(artigo =>(
                        <li  className="collection-item" key ={artigo._id}>
                          <div className="collapsible-header" >
                            <table className='responsive-table striped'>
                              <thead  id={artigo._id}>
                                <tr>
                                  <th>Titulo</th>
                                  <th>Views</th>
                                  <th>Likes</th>
                                  <th>Status</th>
                                </tr>
                              </thead>                        
                              <tbody>
                                <tr>
                                  <td>{artigo.titulo}</td>
                                  <td>{artigo.views}</td>
                                  <td>{artigo.like}</td>
                                  <td>{artigo.status}</td>
                                </tr>
                                <tr></tr>
                              </tbody>
                            </table>  
                          </div>
                            <div className="collapsible-body" id={artigo._id}>
                              <table className='responsive-table striped'>
                                <thead  id={artigo._id}>
                                  <tr>
                                    <th>Descrição</th>
                                  </tr>
                                </thead>                        
                                <tbody>
                                  <tr>
                                    <td>{artigo.descricao}</td>
                                  </tr>
                                </tbody>
                                <thead  id={artigo._id}>
                                  <tr>
                                    <th>Texto</th>
                                  </tr>
                                </thead>                        
                                <tbody>
                                  <tr>
                                    <td>{artigo.texto}</td>
                                  </tr>
                                </tbody>
                                <thead  id={artigo._id}>
                                  <tr>
                                    <th>Url</th>
                                    <th>Vídeo</th>
                                    <th>Podcast</th>
                                  </tr>
                                </thead>                        
                                <tbody>
                                  <tr>
                                    <td>{artigo.url}</td>
                                    <td>{artigo.video}</td>
                                    <td>{artigo.podcast}</td>
                                  </tr>
                                </tbody>
                                <thead  id={artigo._id}>
                                  <tr>
                                    <th>Views</th>
                                    <th>Likes</th>
                                    <th>Dislikes</th>
                                    <th>Status</th>
                                    <th>Escrito em</th>
                                  </tr>
                                </thead>                        
                                <tbody>
                                  <tr>
                                    <td>{artigo.views}</td>
                                    <td>{artigo.like}</td>
                                    <td>{artigo.dislike}</td>
                                    <td>{artigo.status}</td>
                                    <td>{this.retornaData(artigo.createdAt)}</td>
                                  </tr>
                                </tbody>
                                <thead id={artigo._id}>
                                  <tr>
                                    <th>Autor</th>
                                    <th>Temas</th>
                                    <th>Palavras Chaves</th>
                                  </tr>
                                </thead>                        
                                <tbody>
                                  <tr>
                                    <td>{artigo.autor}</td>
                                    <td>{artigo.tema}</td>
                                    <td>{artigo.palavrasChaves}</td>
                                  </tr>
                                </tbody>
                                <thead  id={artigo._id}>
                                  <tr>
                                    <th>Imagem Grande</th>
                                    <th>Imagem Pequena</th>
                                  </tr>
                                </thead>                        
                                <tbody>
                                  <tr>
                                    <td><img className="responsive-img" src={artigo.imgGrande} alt={artigo.titulo} /></td>
                                    <td><img className="responsive-img" src={artigo.imgPequena}  alt={artigo.titulo} /></td>
                                  </tr>
                                </tbody>
                                <thead id={artigo._id}>
                                  <tr>
                                    <th>Alterar Status</th>
                                    <th>Alterar</th>
                                    <th>Deletar</th>
                                  </tr>
                                </thead>                        
                                <tbody>
                                  <tr>
                                    <td><a className='waves-effect waves-light btn' id={artigo._id}>Alterar Status</a></td>
                                    <td><a className='waves-effect waves-light btn' id={artigo._id}>Alterar</a></td>
                                    <td><a className='waves-effect waves-light btn' id={artigo._id}>Deletar</a></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                        </li>
                      ))}
                    </ul>
                    </div>
                  </li>

                </ul>
              </div>
              <div id="test2" className="col s12">Autor / Tema</div>
              <div id="test3" className="col s12">Test 3</div>
              <div id="test4" className="col s12">Test 4</div>
            </div>
          </div>
      );
    }
}
export default Home;