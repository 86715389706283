import React, { Component } from 'react';
import M from  'materialize-css/dist/js/materialize.min.js';
import './App.css';
import Router from './router.js'

console.log(process.env.NODE_ENV);
class App extends Component{
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render(){
    return (
      <div className="App">
            <Router />      
      </div>
    );
  };

};

export default App;
