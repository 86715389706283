import axios from 'axios';
import config from './config/config';

const URL = config.server.url; // dev, hmg, prod 

/*const URL = {
    dev: "http://localhost:3001/",
    hmg: "https://quattro-api.herokuapp.com/api/",
    prod: "https://api.investquattro.com.br:4500/api/", //prod: "https://67.207.83.91:4500/api/",
}*/

const axiosInstance =  axios.create({
    baseURL: URL,
    withCredentials: true,
})

axiosInstance.interceptors.request.use(function (config) {
    localStorage.setItem('X-Cookie-Status', 'Bearer yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyNDc1YmYzMjczZWM1OGQzMjlkNjc1ZSIsImlhdCI6MTY0ODg0Mzc2MywiZXhwIjoxNjQ4OTMwMTYzfQ.l4QSnQganZZGbDt8b9ZBoz8L1g3khoLQoMAdOyaED6c')
    const token = localStorage.getItem('X-Cookie-Status');
    //const token = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMTY0OWQ4MGE4ZTNjM2RlZjNiNGFlZSIsImlhdCI6MTY0NTcyNDEzNSwiZXhwIjoxNjQ1ODEwNTM1fQ.eH-5T6Y4HfMnoUYH2cAcAzcLhS7V1oJLm3a8Sgev9Hk";

    if (token)
        config.headers['X-Cookie-Status'] =  token;

    return config;
});


axiosInstance.interceptors.response.use( (response) => {
    return response;
}, (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        //console.log("rejeitado ...")
        reject(error);
      });
    }

    //console.log("redirect ...")
    window.location.href = '/';

    // to-do: implement refresh token

    // Logout user if token refresh didn't work or user is disabled
    /*
    if (error.config.url == '/api/token/refresh' || error.response.message == 'Account is disabled.') {
      
      TokenStorage.clear();
      router.push({ name: 'root' });

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    

    // Try request again with new token
    return TokenStorage.getNewToken()
      .then((token) => {

        // New request with new token
        const config = error.config;
        config.headers['Authorization'] = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          })
        });

      })
      .catch((error) => {
      	Promise.reject(error);
      });
      */
});


export default axiosInstance
