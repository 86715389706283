import React, { Component } from 'react';
import axiosApi from '../../../axiosApi'

//Paginas
import './index.css';

/* img */

//function nl2br(texto) {
//  return texto.replace(new RegExp('\r?\n','g'), '<br />');
//}

class Login extends Component{
      constructor(props){
        super(props)
        this.state = {
          id: 45711372000164,
          email: '',
          senha: '',
          empresa: '',
          token:'',
        }
        localStorage.clear()

        //pageView.log('home');
      }
      changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
      }

      componentDidMount(){
        const M = window.M;

      }
      
      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }

      login = async (e) =>{
        e.preventDefault()
        await axiosApi.post(`/empresa/autenticacao`,
        {
          email: this.state.email,
          password: this.state.senha
        }
        ).then( res => {
          this.setState({
            "empresa": res.data.emp,
            "token": res.data.token
          });
          
          localStorage.setItem('empresa', res.data.emp);
          localStorage.setItem('plano', res.data.emp.statusPlano);
          localStorage.setItem('cnpj', res.data.emp.cnpj);
          localStorage.setItem('token', res.data.token);

          //window.location.assign("http://www.devmedia.com.br");
          return window.location.replace("/");
        })
        .catch( error => {
          console.log(error)
          if(error.toString().includes('404')){
            this.toastMensagem("Email não encontrado");
          }else if(error.toString().includes('409')){
            this.toastMensagem("Senha incorreta");
          }else if(error.toString().includes('400')){
            this.toastMensagem("Erro ;(. Tente novamente mais tarde ou entre em contato com o suporte");
          }else{
            this.toastMensagem("Error ;(. Entre em contato com o suporte");
          }
        });
      }

      render(){
        const {email, senha} = this.state;
        return(
          <div className="login"> 
              <div className="row">
                <div className="col s10 push-s1 m6 push-m3">
                  <div className="card">
                    <div className="card-content">
                      <span className="card-title">Login</span>
                      <form onSubmit={this.login} name="loginUser">
                        <div className="row">
                          <div className="input-field col s12" title='Email cadastrado na plataforma'>
                            <input onChange={this.changeHandler} value={email} name="email" type="email" className="validate" placeholder="Email cadastrado" required/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s12" title='Senha deve conter 8 digitos, letras maiúsculas e minúsculas, números e caracteres especiais'>
                            <input onChange={this.changeHandler}  value={senha} name="senha" type="password" className="validate" placeholder="Digite sua senha" minLength='6' required/>
                          </div>
                        </div>
                        <div className="row" >
                          <div className="input-field col s12" >
                          <button className="btn waves-effect" type="submit" name="action">Logar
                            <i className="material-icons right">send</i>
                          </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-links">
                      <a href="/registrar">Registre-se</a>
                      <a href="/recuperar-senha">Esqueci a senha</a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      );
    }
}
export default Login;