import React, { Component } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';


//DASHBOARD
import HomeDash from './component/dashboard/home.component/index';
import LoginDash from './component/dashboard/login.component/index';
import RegistrarDash from './component/dashboard/registro.component/index';
import RecuperarDash from './component/dashboard/esqueciSenha.component/index';

//BLOG
import HomeBlog from './component/blog/home.component/index';
import PesquisarBlog from './component/blog/pesquisar.component/index';
import artigoBlog from './component/blog/artigo.component/index';
import adminBlog from './component/blog/admin.component/index';



class Router extends Component{
    constructor(props){
        super(props)
        this.state = {
          /*carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          status: localStorage.getItem('idStatus'),
          id: localStorage.getItem('idAluno'),
          token: localStorage.getItem('X-Cookie-Status')*/
        }
      } 
      
      render(){
          return(
            <BrowserRouter>
            {
              localStorage.getItem('cnpj')
              ?
                localStorage.getItem('plano')
                ?
                
                  <Switch>                                
                    <Route path="/"  component={HomeDash} />                         
                    <Route component={HomeDash} />                      
                  </Switch> 
                :
                  <Switch>                                
                    <Route path="/"  component={HomeDash} />                         
                    <Route component={HomeDash} />                      
                  </Switch> 
                
              :
                <Switch>                       
                  <Route path="/recuperar-senha"  component={RecuperarDash} />            
                  <Route path="/registrar"  component={RegistrarDash} />            
                  <Route path="/login"  component={LoginDash} />            
                  <Route path="/"  component={LoginDash} />                         
                  <Route component={LoginDash} />                      
                </Switch>   

            }
            </BrowserRouter>
          );
      
    }
}
export default Router;