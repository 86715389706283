import React, { Component } from 'react';

import {Sidenav, Dropdown} from 'materialize-css';
//Paginas
import './index.css';
import M from 'materialize-css';

// Imagens
import logoImg from './../../assets/miniLogo2.png';

class Menu extends Component{
    
    componentDidMount(){
        //const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems, {});
          });
        
    }
    deslogar = async () => {
        this.toastMensagem('Deslogando. . .')
        await this.sleep(1);
        localStorage.clear();
        
        return window.location.replace("/login");
    }

    test(){
        this.toastMensagem('Hey')
    }

    toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
    }

    sleep = (segundos) => {
      return new Promise(resolve => setTimeout(resolve, segundos * 1000));
    }
    
    render() {
        return(
            <div className='menuDash'>
                <div id="menuDash" className="navbar-fixed">
                    <nav className="nav-extended">
                        <div className="nav-wrapper">
                            <a href="/" className="brand-logo"><img src={logoImg} /></a>
                            <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">&#9776;</i></a>
                            <ul id="nav-mobile" className="right hide-on-med-and-down">
                                <li><a href="/">Planos</a></li>
                                <li><a class="waves-effect  btn" onClick={this.deslogar}>Deslogar</a></li>
                            </ul>
                        </div>
                    </nav>                  
                </div>
        </div>
        );
    };
};
export default Menu;