import React, { Component } from 'react';

import {Sidenav, Dropdown} from 'materialize-css';
//Paginas
import './index.css';
import M from 'materialize-css';

// Imagens
import logoImg from '../../assets/logoBlog.png';

class Footer extends Component{
    
    componentDidMount(){
        //const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems, {});
          });
        
    }
    
    render() {
        return(
            <div className="page-foolter">                    
                <div className="container">                
                    Nos acompanhe no <a  href="https://www.instagram.com/bree.se_/" target="_blank">Instagram</a> e receba notícias 24h por dia
                </div>
                <div className="footer-copyright">
                    <div className="container-cop">                        
                        <span className="left" >bree.suporteempresarial@gmail.com</span>
                        <span className="right" >Bree.se © 2022 Copyright</span>
                    </div>
                </div>
            </div>
        );
    };
};
export default Footer;