import React, {useState, useEffect} from 'react'
import { Chart } from "react-google-charts";

export const data = [
  ["Task", "Hours per days"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7]
];



const Charts = () =>{  
  return(
    <div>
      <Chart
        chartType="Bar"
        data={data}
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default Charts;