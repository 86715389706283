import React, { Component } from 'react';
import moment from 'moment';
import axiosApi from '../../../axiosApi';
import CurrencyInput from 'react-currency-input-field';


//Paginas
import Menu from '../../../template/menu/index'
import Chart from './../chart.component/index'
import './index.css';

/* img */

//function nl2br(texto) {
//  return texto.replace(new RegExp('\r?\n','g'), '<br />');
//}

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          id: localStorage.getItem('cnpj'),
          token: localStorage.getItem('token'),
          empresa: localStorage.getItem('empresa'),
          movimentacoesMei: [],
          entradas: [],
          saidas: [],
          saldo: [0,0,0], // 0 -> entradas, 1 -> saidas, 2 ->
          valores:[0,0,0,0,0,0,0,0],
          checados: [],
          cad_realizadoEm: '',
          cad_tipo: 0,
          cad_descricao: 0,
          cad_vezes: 1,
          cad_parcela: 0,

        }
        //this.getEmpresa();  
        this.listarMovimentacoes();
        //pageView.log('home');
      }
      changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
      }
      

      // Dados da empresa
      getEmpresa = () =>{
        const {id} = this.state;
        axiosApi.get(`/empresa/${id}/`).then( res => {
            this.setState({
              "empresa": res.data.emp
            });
            console.log(this.state.empresa)
          })
          .catch( error => {
              console.log(error);
              this.toastMensagem("Error: " + error);
          });
      }

      // Listar Movimentações da empresa
      listarMovimentacoes = () =>{        
        const {id} = this.state;
        axiosApi.get(`/movimentacaoMei/${id}`)
        .then(res => {  
            this.setState({ 
              movimentacoesMei: JSON.parse(JSON.stringify(res.data.mov))
            });
            console.log(this.state.movimentacoesMei)
  
            this.saldo();
        })
        .catch(error => {
          console.log(error);
        })
      }

      //Gerar saldo da empresa
      saldo = () =>{
        const mov = this.state.movimentacoesMei;
        let entrada = [];
        let saida = [];
        let saldo = [0,0,0];
        
        for(var i = 0; i < mov.length; i++){
          if (mov[i].tipo == 1){
            entrada = mov[i]
            saldo[0] += mov[i].totalRecebidio
          }else if (mov[i].tipo == 2){
            saida = mov[i]
            saldo[1]  += mov[i].totalRecebidio
          }
        }
        saldo[2] = saldo[0] - saldo[1]
        for(var i=0; i < saldo.length; i++){
          saldo[i] = this.formatarMoeda(saldo[i])
        }
        this.setState({
          entradas: entrada,
          saidas: saida,
          saldo: saldo
        })
      }    

      cadDescricaoMovimentacao = () =>{
        const { checados, valores } = this.state
        let texto = '';
        if(checados[0] && valores[0] > 0)
          texto += 'Dinheiro: R$ ' + valores[0] + "; " 
        if(checados[1] && valores[1] > 0)
          texto += 'Pix: R$ ' + valores[1] + "; "
        if(checados[2] && valores[2] > 0)
          texto += 'Cartão de Débito: R$ ' + valores[2] + "; "
        if(checados[3] && valores[3] > 0){
          texto += `Cartão de Crédito: R$ ${valores[3]} (${this.state.cad_vezes} x ${this.state.cad_parcela}); `
        }
          
        if(checados[4] && valores[4] > 0)
          texto += 'Transferência Bancária: R$ ' + valores[4] + "; "
        if(checados[5] && valores[5] > 0)
          texto += 'Cheque: R$ ' + valores[5]  + "; "

        return texto
      }

      cadastrarEntrada = async () =>{
        const {id} = this.state;
        var body = '';
        if(this.cadastroEPrazo() == 1){
          body = {
            cnpj: id,
            tipo: Number(this.state.cad_tipo),
            formaPag: this.cadastroEPrazo(),
            descPag: Number(this.state.cad_descricao),
            descricaoMovi: this.cadDescricaoMovimentacao(),
            total: this.limpaVariavel(this.state.valores[6]),
            totalRecebidio: this.limpaVariavel(this.state.valores[7]),
            realizadoEm: this.state.cad_realizadoEm
          }
        }else{
          body = {
            cnpj: id,
            tipo: Number(this.state.cad_tipo),
            formaPag: this.cadastroEPrazo(),
            descPag: Number(this.state.cad_descricao),
            descricaoMovi: this.cadDescricaoMovimentacao(),
            total: this.limpaVariavel(this.state.valores[6]),
            totalRecebidio: this.limpaVariavel(this.state.valores[7]),
            realizadoEm: this.state.cad_realizadoEm,
            status: false,
          }
        }
        
        console.log(body)
        try{
          await axiosApi.post(`/movimentacaoMei/cadastrar`,
          body,
          )
          .then( res => {
            this.setState({
              "movimentacaoMei": res.data.mov
            });
            console.log(res.data.mov)
            this.toastMensagem("Movimentação realizada com sucesso.");
            //this.listarMovimentacoes();
            document.querySelector("#cadastrarMovimentacao").reset();
          })
          .catch( error => {
              console.log(error);
              this.toastMensagem("Erro ao cadastrar movimentação. Tente novamente mais tarde, ou tentre em contato com o suporte");
          });

        }catch(error){
          console.log(error)
        }

      }
  
      formatarMoeda = (valor) =>{
        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
      }
      
      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }

      verificaChecado = (e) =>{
        const {checados, valores} = this.state
        if(e.target.name == '1.1'){
          checados[0] = ! checados[0] 
          if(checados[0]){
            document.getElementById('inputDinheiro').disabled = false
          }else{
            document.getElementById('inputDinheiro').disabled = true
            valores[0] = 0
          }
        }
        if(e.target.name == '1.2'){
          checados[1] = ! checados[1] 
          if(checados[1]){
            document.getElementById('inputPix').disabled = false
          }else{
            document.getElementById('inputPix').disabled = true
            valores[1] = 0
          }
        }
        if(e.target.name == '1.3'){
          checados[2] = ! checados[2] 
          if(checados[2]){
            document.getElementById('inputDebito').disabled = false
          }else{
            document.getElementById('inputDebito').disabled = true
            valores[2] = 0
          }
        }
        if(e.target.name == '1.4'){
          checados[3] = ! checados[3] 
          if(checados[3]){
            document.getElementById('inputCredito').disabled = false
            document.getElementById('container-prazo').style.display = "block"
          }else{
            document.getElementById('inputCredito').disabled = true
            valores[3] = 0
            document.getElementById('container-prazo').style.display = "none" 
          }
        }
        if(e.target.name == '1.5'){
          checados[4] = ! checados[4] 
          if(checados[4]){
            document.getElementById('inputTransferencia').disabled = false
          }else{
            document.getElementById('inputTransferencia').disabled = true
            valores[4] = 0
          }
        }
        if(e.target.name == '1.6'){
          checados[5] = ! checados[5] 
          if(checados[5]){
            document.getElementById('inputCheque').disabled = false
          }else{
            document.getElementById('inputCheque').disabled = true
            valores[5] = 0
          }
        }
        this.setState({
          valores: valores
        })
        this.saldoValoresEntrada()
      }

      valorEntrada = (e) =>{
        e.preventDefault()
        const { valores }= this.state
         /* valores[0] = Number(this.limpaVariavel(document.querySelector("#inputDinheiro").defaultValue))
          valores[1] = Number(this.limpaVariavel(document.querySelector("#inputPix").defaultValue))
          valores[2] = Number(this.limpaVariavel(document.querySelector("#inputDebito").defaultValue))
          valores[3] = Number(this.limpaVariavel(document.querySelector("#inputCredito").defaultValue))
          valores[4] = Number(this.limpaVariavel(document.querySelector("#inputTransferencia").defaultValue))
          valores[5] = Number(this.limpaVariavel(document.querySelector("#inputCheque").defaultValue))*/

        if(e.target.defaultValue < 0 || e.target.defaultValue == undefined || e.target.defaultValue == null)
          e.target.defaultValue = 0
        if (e.target.name == "inputDinheiro")
          valores[0] = Number(this.limpaVariavel(e.target.defaultValue))
        if (e.target.name == "inputPix")
          valores[1] = Number(this.limpaVariavel(e.target.defaultValue))
        if (e.target.name == "inputDebito")
          valores[2] = Number(this.limpaVariavel(e.target.defaultValue))
        if (e.target.name == "inputCredito")
          valores[3] = Number(this.limpaVariavel(e.target.defaultValue))
        if (e.target.name == "inputTransferencia")
          valores[4] = Number(this.limpaVariavel(e.target.defaultValue))
        if (e.target.name == "inputCheque")
          valores[5] = Number(this.limpaVariavel(e.target.defaultValue))

        this.setState({
          valores: valores
        })
        this.saldoValoresEntrada()
      }

      saldoValoresEntrada = () =>{
        const { valores }= this.state
        // Total da operação
        valores[6] = valores[0] + valores[1] + valores[2] + valores[3] + valores[4] + valores[5]
        valores[6] = this.formatarMoeda(valores[6])
        // Total recebido
        valores[7] = valores[0] + valores[1] + valores[2] + valores[4] + valores[5]
        valores[7] = this.formatarMoeda(valores[7])
        this.setState({
          valores: valores
        })
      }

      limpaVariavel = (valor) =>{
        return String(valor.replaceAll('.', '').replaceAll('R', '').replaceAll('$', '').replaceAll(',', '.').replaceAll('_', '').replaceAll(' ', '').replaceAll('/', ''))
      }

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          M.Sidenav.init(document.querySelectorAll('.sidenav'), {});
        });
        document.addEventListener('DOMContentLoaded', function() {
          M.Tabs.init(document.querySelectorAll('.tabs'), {});
        });
        document.addEventListener('DOMContentLoaded', function() {
          M.FormSelect.init(document.querySelectorAll('select'), {});
        });
      }
      
      cadastroEPrazo = () =>{
        const {checados, valores} = this.state
        let vista = false;
        let prazo = false
        if((checados[0] && valores[0] > 0) || (checados[1] && valores[1] > 0) || (checados[2] && valores[2] > 0) || (checados[4] && valores[4] > 0) || (checados[5] && valores[5] > 0))
          vista = true

        if(checados[3] && valores[3] > 0){
          prazo = true
        }

        if(!prazo)
          return 1
        if(!vista)
          return 2
        return 3
      }

      verificaCadastroTransacao = e =>{
        e.preventDefault()
        if(this.state.cad_tipo == "0")
          return this.toastMensagem('Selecione a transação.')
        if(this.state.cad_descricao == "0")
          return this.toastMensagem('Descreva a transação.')
        if(this.state.valores[6] == 0 || this.state.valores[6] == "R$ 0,00")
          return this.toastMensagem('Insira o valor da transação.')
        if(this.state.checados[3] == true){
          if(this.state.cad_parcela == 0  || this.state.cad_parcela == "R$ 0,00" || this.state.cad_parcela == undefined || this.state.cad_parcela == null)
            return this.toastMensagem('Insira o valor da parcela.')
        }

        this.cadastrarEntrada();
      }

      separarPorPontoVirgula = (v) =>{
        let vi = v.split(";")
        //console.log(vi.pop())
        return vi.slice(0,-1)
      }

      gerarSelect = (tipo) =>{
        let select = []
        if(tipo == 0)
          select = ["Descrição da Movimentação"]
        else if(tipo == 1)
          select = ["Descrição da transação", "Recebimento de Sálário", "Recebimento de Serviço prestado", "Recebido de venda de mercadoria", "Serviço prestado e mercadoria vendida", "Outras formas de entrada"]
        else if(tipo == 2)
          select = ["Descrição da transação", "Pagamento Funcionarios", "Pagamento Contas (Luz, Água ...)", "Pagamento Fornecedores", "Comprar de Mercadorias", "Compras", "Outras formas de saídas"]
        return select
      }

      deslogar = async () => {
        this.toastMensagem('Deslogando. . .')
        await this.sleep(1);
        localStorage.clear();
        
        return window.location.replace("/");
      }

      sleep = (segundos) => {
        return new Promise(resolve => setTimeout(resolve, segundos * 1000));
      }
      
      retornarSelectSelecionado = (tipo, index) =>{
        let select = this.gerarSelect(tipo)
        return select[index]
      }

      retornarDataFormatada = (data) =>{
        var date
        var data = moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
        var now = moment(new Date(), "YYYY-MM-DD").format("DD/MM/YYYY")
        let dias = this.retornarCalculoDeDias(now, data)
        if(data == now){
          date = "Hoje";
        }else if(dias == -1){
          date = "Ontem";
        }else{
          date = data
        }
        return date;
      }
      
      retornarCalculoDeDias= (atual, passado) =>{
        let diff = moment(passado,"DD/MM/YYYY").diff(moment(atual,"DD/MM/YYYY"));
        return moment.duration(diff).asDays();
      }

      retornarQntMovAbertas = () =>{
        const { movimentacoesMei } = this.state
        let cont = 0;
        for(var i = 0; i < movimentacoesMei.length; i ++){
          if(!this.state.movimentacoesMei[i].status){
            cont ++;
          }
        }
        return cont;
      }

      render(){
        const {empresa, movimentacoesMei, entradas, saidas, saldo, valores, checados, cad_realizadoEm, cad_tipo, cad_descricao, cad_vezes, cad_parcela} = this.state;
        return(
          <div className="home"> 
          <Menu />
            <ul className="tabs tabs-fixed-width tab-demo z-depth-1">
              <li className="tab"><a  href="#home">Dashboard</a></li>
              <li className="tab"><a className="active" href="#movimentacoes">Movimentações</a></li>
              <li className="tab"><a href="#outros">Outros</a></li>
              <li className="tab"><a href="#perfil">Perfil</a></li>
            </ul>
            <ul className="sidenav" id="mobile-demo">
              <li><a href="/">Plano</a></li>
              <li><a className="waves-effect  btn" onClick={this.deslogar}>Deslogar</a></li>
            </ul>

            {/* Home */} 
            <div id="home" className="container-tab col s12">
              <div className='row'>
                <div className='col s10 push-s1 m8 push-m2 l6 push-l3'>
                  <div className="card">
                    <div className="card-content">
                      <p>Controle de Entradas e saídas {empresa.nomeFantasia}</p>
                      <h1 title='Saldo atual da empresa'>R$ {saldo[2]}</h1>
                    </div>
                    <div className="card-content grey lighten-5">
                      <div id="test4">Grafico anual</div>
                      <div id="test5"><Chart /></div>
                      <div id="test6">Gráfico 7 dias</div>
                    </div>
                    <div className="card-tabs">
                      <ul className="tabs tabs-fixed-width">
                        <li className="tab"><a href="#test4">Ano</a></li>
                        <li className="tab"><a className="active" href="#test5">Mês</a></li>
                        <li className="tab"><a href="#test6">7 dias</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

            {/* MOVIMENTAÇÕES */} 
            <div id="movimentacoes" className="container-tab col s12">
              <div className='row'>
                  <div className='col s10 push-s1 m8 push-m2 l6 push-l3'>
                    <div className="card">
                      <div className="card-content">
                        <h3 title='Saldo atual da empresa'>Controle</h3>
                      </div>
                      <div className="card-content ">
                        <div className="card-tabs">
                          <ul className="tabs tabs-fixed-width">
                            <li className="tab"><a  href="#cadEntradas">Entradas / Saídas</a></li>
                            <li className="tab"><a href="#emAberto" className="active">Em Aberto</a></li>
                          </ul>
                        </div>
                        <div id="cadEntradas">
                          <form onSubmit={this.verificaCadastroTransacao} name="cadastrarMovimentacao" id="cadastrarMovimentacao">
                            <div className="input-field col s6 push-s3">
                              <select name="cad_tipo" onChange={this.changeHandler} defaultValue={cad_tipo} required>
                                <option value="0" disabled selected>ESCOLHA O LANÇAMENTO</option>
                                <option value="1">ENTRADA</option>
                                <option value="2">SAÍDA</option>
                              </select>
                            </div>
                            <div className="input-field col s12">
                                <span className='left'>Descrição</span>
                                {
                                  this.state.cad_tipo == 0
                                    ?
                                        <option value="0" disabled selected>Escolha uma forma de transação</option>                                    
                                    :
                                      this.state.cad_tipo == '1'
                                      ?
                                        <select name='cad_descricao' defaultValue={cad_descricao} className="browser-default" onChange={this.changeHandler}>                                        
                                          {this.gerarSelect(1).map((item, i) =>
                                            i == 0 
                                            ?
                                              <option value={i} key={i} disabled selected>{item}</option>
                                            :
                                              <option value={i} key={i}>{item}</option>
                                          )}
                                        </select>
                                      :
                                        <select name="cad_descricao" defaultValue={cad_descricao} className="browser-default"  onChange={this.changeHandler}>                                        
                                          {this.gerarSelect(2).map((item, i) =>
                                            i == 0 
                                            ?
                                              <option value={i} key={i} disabled selected>{item}</option>
                                            :
                                              <option value={i} key={i}>{item}</option>
                                          )}
                                      </select>
                                }
                            </div>
                            <fieldset>
                              <legend>Forma de Pagamento total da operação {valores[6]} </legend>
                              <div className="row">
                              <div className="col s12">
                                <p className='row'>
                                  <label className="input-field col s4">
                                    <input type="checkbox" className="filled-in" name="1.1" onChange={this.verificaChecado}/>
                                    <span>Dinheiro</span>
                                  </label>
                                  <div className="input-field col s6 push-s1" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                                    <CurrencyInput id="inputDinheiro" name="inputDinheiro" placeholder="Please enter a number" defaultValue={valores[0]} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} disabled onKeyUp={this.valorEntrada}/> 
                                  </div>                            
                                </p>                              
                                <p className='row'>
                                  <label className="input-field col s4">
                                    <input type="checkbox" className="filled-in" name="1.2" onChange={this.verificaChecado}/>
                                    <span>Pix</span>
                                  </label>
                                  <div className="input-field col s6 push-s1" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                                    <CurrencyInput disabled id="inputPix" name="inputPix" placeholder="Please enter a number" defaultValue={valores[1]} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} onKeyUp={this.valorEntrada}/>
                                </div>  
                                </p>                              
                                <p className='row'>
                                  <label className="input-field col s4">
                                    <input type="checkbox" className="filled-in" name="1.3" onChange={this.verificaChecado}/>
                                    <span>Cartão de Débito</span>
                                  </label>
                                  <div className="input-field col s6 push-s1" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                                    <CurrencyInput disabled id="inputDebito" name="inputDebito" placeholder="Please enter a number" defaultValue={valores[2]} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} onKeyUp={this.valorEntrada}/>
                                </div>  
                                </p>                              
                                <p className='row'>
                                  <label className="input-field col s4">
                                    <input type="checkbox" className="filled-in" name="1.4" onChange={this.verificaChecado}/>
                                    <span>Cartão de Crédito</span>
                                  </label>
                                  <div className="input-field col s6 push-s1" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                                    <CurrencyInput disabled id="inputCredito" name="inputCredito" placeholder="Please enter a number" defaultValue={valores[3]} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} onKeyUp={this.valorEntrada}/>
                                  </div>  
                                  <div id="container-prazo">
                                    <fieldset>
                                      <legend>À Prazo</legend>
                                      <div className="input-field col s3 push-s1" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                                        <input type="number" name="cad_vezes" value={cad_vezes} onChange={this.changeHandler} id="qntParcelas" placeholder='5 vezes' min="1" />
                                      </div>  
                                      <div className="input-field col s6 push-s2" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                                        <CurrencyInput  id="inputParcela" placeholder="de 100,00" intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} name="cad_parcela" onChange={this.changeHandler} />
                                      </div>
                                    </fieldset>
                                  </div>
                                </p>                              
                                <p className='row'>
                                  <label className="input-field col s4">
                                    <input type="checkbox" className="filled-in" name="1.5" onChange={this.verificaChecado}/>
                                    <span>Transferência Bancária</span>
                                  </label>
                                  <div className="input-field col s6 push-s1" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                                    <CurrencyInput disabled id="inputTransferencia" name="inputTransferencia" placeholder="Please enter a number" defaultValue={valores[4]} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} onKeyUp={this.valorEntrada}/>
                                </div>  
                                </p>                              
                                <p className='row'>
                                  <label className="input-field col s4" >
                                    <input type="checkbox" className="filled-in" name="1.6" onChange={this.verificaChecado}/>
                                    <span>Cheque</span>
                                  </label>
                                  <div className="input-field col s6 push-s1" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                                    <CurrencyInput disabled id="inputCheque" name="inputCheque" placeholder="Please enter a number" defaultValue={valores[5]} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} onKeyUp={this.valorEntrada}/>
                                </div>  
                                </p>                              
                              </div>
                            </div>
                            </fieldset>
                            

                            <div className="row">
                              <div className="input-field col s6 push-s3" title='Ta em que ocorreu a operação.' placeholder='Data do acontecimento.'>
                                <input type="date" name="cad_realizadoEm" value={cad_realizadoEm} onChange={this.changeHandler} required/>
                              <label>Data de acontecimento</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s6 push-s3" title='Total já recebido nesta operação'>
                                <input disabled type="text" id="input-example" name="input-name" placeholder="Valor total que já se encontra em caixa" 
                                value={valores[7]}/>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12" title='Total já recebido nesta operação'>
                                <button className="btn col s10 push-s1 waves-effect" type="submit" name="action">Registrar
                                  <i className="material-icons right">send</i>
                                </button>
                              </div>
                            </div>                            
                          </form>
                        </div>
                        <div id="emAberto">
                          <div className="collection">
                            {
                              this.retornarQntMovAbertas() < 1
                              ?
                                <p>
                                  Não há movimentações aguardando pagamento.
                                </p>
                              :
                              movimentacoesMei.map((i) => 
                              i.status
                              ?
                                ''
                              :
                                <li className="collection-item" key={i._id} name={i._id}>
                                  <div>
                                    <span>{this.retornarSelectSelecionado(i.tipo, i.descPag)} </span>
                                    <span> {this.formatarMoeda(i.total - i.totalRecebidio)}</span>
                                    <a href="#!" className="secondary-content"><i className="material-icons">update</i></a>
                                  </div>
                                </li>
                            )}
                              
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              
                {/* LISTA DE MOVIMENTAÇÕES*/}
                <div className="row">
                  <div className="col s12">
                    <ul className="collection col s10 push-s1">
                      {
                        movimentacoesMei.length < 1
                        ?
                          <p>Não há movimentações cadastradas no momento. Para cadastrar uma movimentação faça os seguintes passos: <br />
                            Movimentações > Controle > Entradas / Saídas
                          </p>
                        :
                        movimentacoesMei.map((i) => 
                        <li className="collection-item avatar" key={i._id}>
                          <li className="collection-item avatar">
                              {
                                i.tipo == 1
                                ?
                                  <i className="material-icons circle green">download</i>
                                :
                                  <i className="material-icons circle red">publish</i>
                              }
                              <p>{this.retornarDataFormatada(i.realizadoEm)}</p>
                              <span className="title">{this.retornarSelectSelecionado(i.tipo, i.descPag)}</span>
                              <p>
                                
                                <span className='span-valor'>{this.formatarMoeda(i.total)}</span>

                                {this.separarPorPontoVirgula(i.descricaoMovi).map((item, contador) =>
                                  <span key={contador} className="span-descricao">{item}</span>                        
                                )
                                
                                } 
                              </p>
                              {
                                i.status
                                ?
                                  <a href="#!" className="secondary-content"><i className="material-icons">done_all</i></a>
                                :
                                  <a href="#!" className="secondary-content"><i className="material-icons">hourglass_empty</i></a>
                              }
                            </li>
                        </li>
                      )}
                       
                    </ul>
                  </div>
                </div>
            </div>

            {/* Outros */} 
            <div id="outros" className="container-tab col s12">
              <p>Cadastre outros itens</p>
            </div>
            
            {/* Perfil */} 
            <div id="perfil" className="container-tab col s12">
              <p>Perfil</p>
            </div>
            
        </div>
      );
    }
}
export default Home;