import React, { Component } from 'react';
import axiosApi from '../../../axiosApi'

//Paginas
import './index.css';

/* img */

//function nl2br(texto) {
//  return texto.replace(new RegExp('\r?\n','g'), '<br />');
//}

class EsqueciSenha extends Component{
      constructor(props){
        super(props)
        this.state = {
          email: '',
        }
      }

      changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
      }
      getEmpresa = () =>{
        const {id} = this.state;
        axiosApi.get(`/empresa/${id}/`).then( res => {
            this.setState({
              "empresa": res.data.emp
            });
            console.log(this.state.empresa)
          })
          .catch( error => {
              console.log(error);
              this.toastMensagem("Error: " + error);
          });
      }
      listarMovimentacoes = () =>{        
        const {id} = this.state;
        axiosApi.get(`/movimentacaoMei/${id}`)
        .then(res => {  
            this.setState({ 
              movimentacoesMei: JSON.parse(JSON.stringify(res.data.mov))
            });
            console.log(this.state.movimentacoesMei)
  
        })
        .catch(error => {
          console.log(error);
        })
      }

      componentDidMount(){
        const M = window.M;

      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }

      recuperar = async (e) =>{
        e.preventDefault()
        this.toastMensagem('Aguarde. . .')
        await axiosApi.post(`/empresa/esqueci_a_senha`,
        {
          email: this.state.email,
        }
        ).then( res => {
          this.setState({
            //"empresa": res.data.emp,
            //"token": res.data.token
          });
          this.toastMensagem('Senha atualizada, verifique seu email.')
          //window.location.assign("http://www.devmedia.com.br");
          return window.location.replace("/");
        })
        .catch( error => {
            if(error.toString().includes('404')){
              this.toastMensagem("Email não encontrado");
            }else if(error.toString().includes('409')){
              this.toastMensagem("Senha incorreta");
            }else if(error.toString().includes('400')){
              this.toastMensagem("Erro ;(. Tente novamente mais tarde ou entre em contato com o suporte");
            }else{
              this.toastMensagem("Error ;(. Entre em contato com o suporte");
            }
        });
      }
      render(){
        const {email} = this.state;
        return(
          <div className="recuperar"> 
              <div className="row">
                <div className="col s10 push-s1 m6 push-m3">
                  <div className="card">
                    <div className="card-content">
                      <span className="card-title">Recuperar Senha</span>
                      <form onSubmit={this.recuperar} name="recuperarSenhaUser">
                        <div className="row">
                          <div className="input-field col s12" title='Email cadastrado na plataforma'>
                            <input onChange={this.changeHandler} value={email} name="email" type="email" className="validate" placeholder="Email cadastrado na plataforma" required/>
                          </div>
                        </div>
                        <div className="row" >
                          <div className="input-field col s12" >
                          <button className="btn waves-effect" type="submit" name="action">Recuperar
                            <i className="material-icons right">send</i>
                          </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-links">
                      <a href="/login">Logar</a>
                      <a href="/registrar">Registre-se</a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      );
    }
}
export default EsqueciSenha;