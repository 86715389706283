import React, { Component } from 'react';

import {Sidenav, Dropdown} from 'materialize-css';
//Paginas
import './index.css';
import M from 'materialize-css';

// Imagens
import logoImg from '../../assets/logoBlog.png';

class Menu extends Component{
    
    componentDidMount(){
        //const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems, {});
          });
        
    }
    
    render() {
        return(
            <div>
                <div id="menu" className="navbar-fixed">
                    <nav className="nav-extended">
                        <div className="nav-wrapper">
                            <div className="row">
                                <div className="col s12 ">  
                                    <a href="/" className="brand-logo center responsive-img"><img src={logoImg} /></a>
                                </div>                                
                            </div>
                        </div>
                    </nav>             
                </div>
        </div>
        );
    };
};
export default Menu;