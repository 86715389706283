import React, { Component } from 'react';
import axiosApi from '../../../axiosApi';
import InputMask from 'react-input-mask';

//Paginas
import './index.css';

/* img */

//function nl2br(texto) {
//  return texto.replace(new RegExp('\r?\n','g'), '<br />');
//}

class Registrar extends Component{
      constructor(props){
        super(props)
        this.state = {
          email: '',
          senha: '',
          empresa: '',
          token:'',
          confirmaSenha: '', 
          cnpj:'', 
          porte: 0, 
          telefone: '', 
          abertoEm: '',
        }

        //pageView.log('home');
      }
      changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
      }

      componentDidMount(){
        const M = window.M;
        M.FormSelect.init(document.querySelectorAll('select'), {});
      }

      limpaVariavel = (valor) =>{
        return String(valor.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '').replaceAll('_', '').replaceAll('.', '').replaceAll('/', ''))
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }

      cadastrar = async () =>{
        await axiosApi.post(`/empresa/registrar`,
        {
          cnpj: this.limpaVariavel(this.state.cnpj),
          porte: Number(this.state.porte),
          telefone: this.limpaVariavel(this.state.telefone),
          email: this.state.email,
          abertoEm: this.state.abertoEm,
          password: this.state.senha
        }
        ).then( res => {
          this.setState({
            //"empresa": res.data.emp,
            //"token": res.data.token
          });
          this.toastMensagem('Empresa cadastrada com sucesso. Verifique seu email.')
          //window.location.assign("http://www.devmedia.com.br");
          return window.location.replace("/");

        })
        .catch( error => {
            if(error.toString().includes('404')){
              this.toastMensagem("Email não encontrado");
            }else if(error.toString().includes('409')){
              this.toastMensagem("Senha incorreta");
            }else if(error.toString().includes('400')){
              this.toastMensagem("Erro ;(. Tente novamente mais tarde ou entre em contato com o suporte");
            }else{
              this.toastMensagem("Error ;(. Entre em contato com o suporte");
            }
        });
      }

      verificacao = e =>{
        e.preventDefault();
        var regex = /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[0-9]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/; 

        if(this.state.senha !== this.state.confirmaSenha)
          return this.toastMensagem('Senha devem ser idênticas.')
        if(!regex.exec(this.state.senha))
          return this.toastMensagem('Senha deve cumprir os requesitos mínimo. 1 Letra Maiúscula, 1 Número e 1 Caracter especial.')
        if(this.state.cnpj.includes('_'))
          return this.toastMensagem('Preencha o CNPJ corretamente.')
        console.log(this.limpaVariavel(this.state.cnpj))
        if(this.limpaVariavel(this.state.cnpj).length !== 14)
          return this.toastMensagem('Preencha o CNPJ corretamente.')

        if(this.state.porte === 0)
          return this.toastMensagem('Especifique o porte da empresa.')
        console.log(this.limpaVariavel(this.state.telefone))
        if(this.limpaVariavel(this.state.telefone).length < 10)
          return this.toastMensagem('Preencha o telefone corretamente.')

        this.toastMensagem('Processando. . .')
        this.cadastrar();
      }
      render(){
        const {email, senha, confirmaSenha, cnpj, porte, telefone, abertoEm} = this.state;
        return(
          <div className="register"> 
              <div className="row">
                <div className="col s10 push-s1 m8 push-m2 l6 push-l3">
                  <div className="card">
                    <div className="card-content">
                      <span className="card-title">Cadastre-se</span>
                      <form onSubmit={this.verificacao} name="registerUser">
                        <div className="row">
                          <div className="input-field col s12" title='Email cadastrado na plataforma.'>
                            <input onChange={this.changeHandler} value={email} name="email" type="email" className="validate" placeholder="Email" required/>
                          </div>
                        </div>
                        
                        <small>Requesitos mínimos: 1 Letra Maiúscula, 1 Número e 1 Caracter especial</small>
                        <div className="row">
                          <div className="input-field col s6" title='Senha deve conter 8 digitos, letras maiúsculas e minúsculas, números e caracteres especiais.'>
                            <input onChange={this.changeHandler}  value={senha} name="senha" type="password" className="validate" placeholder="Digite sua senha" minLength='8' required/>
                          </div>
                          <div className="input-field col s6" title='A confirmação da senha deve ser igual a senha.'>
                            <input onChange={this.changeHandler}  value={confirmaSenha} name="confirmaSenha" type="password" className="validate" placeholder="Confrme sua senha" minLength='8' required/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s6" title='CNPJ da sua empresa ou da empresa em que trabalha.'>
                            <InputMask onChange={this.changeHandler}  value={cnpj} name="cnpj" mask="99.999.999/9999-99" type="text" className="validate" placeholder="CNPJ " minLength={14} required /> 
                          </div>
                          <div className="input-field col s6" title='Aceitamenos apenas empresas do Simples Nacional.'>
                            <select onChange={this.changeHandler}  value={porte} name="porte" id="porte" required>
                              <option  value="0" disabled selected>Porte</option>
                              <option value="1">MEI</option>
                              <option value="2">MP</option>
                              <option value="3">EPP</option>
                            </select>
                            <label forHtml="porte">Qual o porte da empresa?</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s6" title='Telefone de contato da empresa.'>
                            <InputMask onChange={this.changeHandler}  mask="(99) 99999-9999" value={telefone} name="telefone" type="text" className="validate" placeholder="21900000000" required/>
                          </div>
                          <div className="input-field col s6" title='Qual a data de abertura da empresa?'>
                            <input onChange={this.changeHandler}  value={abertoEm} name="abertoEm" type="date" className="validate" placeholder="Data de abertura"  required/>
                          </div>
                        </div>
                        <div className="row" >
                          <div className="input-field col s12" >
                          <button className="btn waves-effect" type="submit" name="action">Cadastrar Grátis
                            <i className="material-icons right">send</i>
                          </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-links">
                      <a href="/login">Logar</a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      );
    }
}
export default Registrar;