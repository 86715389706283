import React, { Component } from 'react';
import axiosApi from '../../../axiosApi'
import moment from 'moment';

//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
//import moment from 'moment';
//import { Pie } from 'react-chartjs-2';
//import { Link }  from 'react-router-dom';

//Paginas
import Menu from '../../../template/menuBlog/index';
import Footer from '../../../template/footerBlog/index';
import './index.css';
import apiUrl from '../../../axiosApi';

/* img */

import IconBree from '../../../assets/logoogete.png';

//function nl2br(texto) {
//  return texto.replace(new RegExp('\r?\n','g'), '<br />');
//}

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'',
          titulo: '',
          texto: '',
          descricao: '',
          autor: '',
          data: '',
          dislike: 0,
          like: 0,
          views: 0,
          tema: [],
          palavrasChaves: '',
          idArtigo: localStorage.getItem('idArtigo'),
          imgGrande: '',
          imgPequena: '',
          video: '',
          podcast: '',
          audioTexto: '',
          url:'',
          status: '',
          testeURL: props.match.params.slug,
        }
        this.getIdpelaUrl(props.match.params.slug);
        this.metas();
      }
      
      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.sidenav');
          var instances = M.Sidenav.init(elems, {});
        });
      }

      getIdpelaUrl = async (url) =>{
        await axiosApi.get("/artigo/url/"+ url, 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {        
            //Dados Pessoais
            console.log(res.data.artigo[0]._id);
            localStorage.setItem('idArtigo', res.data.artigo[0]._id);
            this.getArtigo(res.data.artigo[0]._id);
            
        })
        .catch(error => {
          console.log(error);
        })    
        
      
      
      }

      retornaTextoHtml = (t) => {
        var p = document.getElementById("text");
        p.innerHTML = t;
      }

      getArtigo = async (id) => {
        await axiosApi.get("/artigo/"+ id, 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {        
            //Dados Pessoais
            this.setState({
              "id": res.data.artigo.id,
              "titulo": res.data.artigo.titulo,
              "texto": res.data.artigo.texto,
              "descricao": res.data.artigo.descricao,
              "autor":  res.data.artigo.autor,
              "data":  res.data.artigo.createdAt,
              "dislike": res.data.artigo.dislike,
              "like": res.data.artigo.like,
              "views": res.data.artigo.views,
              "tema": res.data.artigo.tema,
              "palavrasChaves": res.data.artigo.palavrasChaves,
              "idArtigo": res.data.artigo._id,
              "imgGrande": res.data.artigo.imgGrande,
              "imgPequena": res.data.artigo.imgPequena,
              "video": res.data.artigo.video,
              "podcast": res.data.artigo.podcast,
              "audioTexto": res.data.artigo.audioTexto,
              "url": res.data.artigo.url,
              "status":res.data.artigo.status,
              "autor":res.data.artigo.autor.nome,
              
            });
            this.retornaTextoHtml(res.data.artigo.texto);
            
        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      dislike = async () => { 
        await axiosApi.put("/artigo/dislike/"+ localStorage.getItem('idArtigo'), 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {
            document.querySelector('.curtir').style.display = "none";        
            this.toastMensagem('Obrigado por votar!');
            console.log(res.data);
        })
        .catch(error => {
          console.log(error);
        })       
      }

      like = async () => { 
        await axiosApi.put("/artigo/like/"+ localStorage.getItem('idArtigo'), 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {
            document.querySelector('.curtir').style.display = "none";        
            this.toastMensagem('Obrigado por votar!');
            console.log(res.data);
        })
        .catch(error => {
          console.log(error);
        })       
        

      }

      metas = async () => { 
        //document.write('<meta name="viewport" content="width=1024...');
        //document.write('<link rel="icon" href={IconBree} />');
        //document.write('<link rel="apple-touch-icon" href={IconBree} />');
        document.write('<meta short_name="Bree Suporte Empresarial" name="Blog Bree Suporte Empresarial" content="Uma empresa focada em MEIs, ME e EPP. Alcance lugares mais altos com a Bree.se"/>');
        //<link rel="icon" href="logoogete.png" />
        
        //<link rel="apple-touch-icon" href="logoogete.png" />

      }

      compartilhar = async () => { 
        if (navigator.userAgentData != undefined && navigator.userAgentData.mobile) {
          navigator.share({
            title: this.titulo,
            text: this.descricao,
            url: window.location.href
          })
          .then(() => this.toastMensagem('Compartilhando. . .'))
          //.then(() => console.log('Successfully shared! <3'))
          //.catch(() => console.log('Oh oh! Something went wrong:', error));
          .catch(() => this.toastMensagem('Oh oh! Aconteceu algo de errado ;('));
        }else{
          navigator.clipboard.writeText(window.location.href);
          this.toastMensagem('Link Copiado. . .');
        }
        

      }

      handleClick2(letter) {
        this.setState({ justClicked: letter });
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'});
      }

      pesquisarTags = e =>{
        e.preventDefault();
        //localStorage.setItem('idArtigo', e.target.id);
        window.location.assign("/pesquisar/artigos/" + e.target.id);
      }

      render(){
        const {titulo, data, texto, descricao, palavrasChaves, autor, audioTexto, podcast, video} = this.state;
        return(
          <div className="artigoBlog"> 
          <Menu />
            <div className='propaganda'>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9735532632608284"
            crossorigin="anonymous"></script>
              <img src="https://static-wp-tor15-prd.torcedores.com/wp-content/uploads/2021/07/fluminense-escudo.jpg" alt="propagnada" />
            </div>
            <div className='artigo'>
              <div className='title'>
                <h3>{titulo}</h3>
                <p>{descricao}</p>
                <h5>por {autor}</h5>
                <h5>{moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")} <a className='btn' onClick={this.compartilhar} ><i className="material-icons" id="share">share</i></a></h5>
              </div>
              <div className='corpo'>
                <div className='text' id="text">
                </div>
              </div>
              <div className='curtir'>
                <h3>Gostou deste artigo?</h3>
                <a className='btn' onClick={this.like} ><i className="material-icons" id="green">thumb_up</i></a>
                <a className='btn' onClick={this.dislike} ><i className="material-icons" id="red">thumb_down</i></a>  
              </div>
              <div className='tags'>  
                {palavrasChaves.split(";")
                .map(i=>(              
                  <span className='chips' onClick={this.pesquisarTags} id={i}>{i}</span>
                ))}
              </div>                          
            </div>
            <div className='propaganda'>
              <img src="https://static-wp-tor15-prd.torcedores.com/wp-content/uploads/2021/07/fluminense-escudo.jpg" alt="propagnada" />
            </div>
            <Footer />
          </div>
      );
    }
}
export default Home;